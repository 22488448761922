<template>
  <div>
    <buildFieldsRows
            :templateContent="upperParams"
            v-model="value"
            languageIndex="calcStep"
    ></buildFieldsRows>

    <configContainer
            title="Evaluation">
      <template v-slot:content>
        <evalContentTable
                v-model="localEval"
                operatorType="calc"
                :correctOperators="true"
                :isValid="isValid"
                :result="result"
        ></evalContentTable>
      </template>
    </configContainer>
  </div>
</template>
<script>
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
import evalContentTable from "@/components/legacy/evalContentTable.vue";
import configContainer from "@/commonComponents/configContainer.vue";
export default {
  components: {
    buildFieldsRows,
    evalContentTable,
    configContainer,
  },

  data() {
    return {
      upperParams: {
        fields: {
          description: {
            type: "textarea",
            fieldAttrInput: {
              class: 'required',
              id:'calcStep_description'
            },
          },
        },
        disableAllInputs:typeof this.result.disableAllInputs!='undefined'?this.result.disableAllInputs:false
      },
    };
  },
  computed: {
    value: {
      get() {
        let value = {};
        if (typeof this.stepStoredValue != "undefined") {
          value = this.stepStoredValue;
        }
        return value;
      },
      set(newVal) {
        console.log(this.result);
        this.$emit("stored-value-changed", newVal);
      },
    },
    localEval: {
      get() {
        let localEval = {};
        if (typeof this.value.eval != "undefined") {
          localEval = this.value.eval;
        }
        return localEval;
      },
      set(newVal) {
        let localStoredStep = { ...this.stepStoredValue };
        this.$set(localStoredStep, "eval", newVal);

        this.$emit("stored-value-changed", localStoredStep);
      },
    },
  },

  props: [
    "result",
    "stepStoredValue",
    "stepType",
    "groupedStepDependentInfo",
    "stepID",
    "additionalStepsInfo",
    "isValid"
  ],
};
</script>